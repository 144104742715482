.save-bottombar {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f2f2f2;
  display: flex;
  z-index: 1000;
  padding: 15px;
  justify-content: flex-end;
  left: 0;
}

table input[type="number"]::-webkit-inner-spin-button,
table input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

table input[type="number"] {
  -moz-appearance: textfield;
}

.remove-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 0;
  height: 0;
}
.remove-range::-moz-range-thumb {
  width: 0;
  height: 0;
}

.listino-prezzi > :not(caption) > * > * {
  padding: 0.35rem 0.35rem !important;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  border: 1px solid #ccc;
  border-radius: 4px;

  overflow-x: scroll; /* Enable horizontal scroll */
}

.grid-header {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  background-color: #f5f5f5;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  flex-wrap: nowrap; /* Prevent flex items from wrapping */
  overflow-x: auto; /* Enable horizontal overflow */
  width: max-content; /* Set width to the maximum content size */
}
.grid-row > .grid-column {
  grid-row: 1;
}


.grid-column {
  padding: 5px;
  text-align: center;
  width: 120px; /* Ensure minimum width of 120px */
  border-right: 1px solid #ccc;
}

.grid-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  border-bottom: 1px solid #ccc;
  flex-wrap: nowrap; /* Prevent flex items from wrapping */
  overflow-x: auto; /* Enable horizontal overflow */
  width: max-content; /* Set width to the maximum content size */
}

.filled {
  background-color: yellow;
}
