.gridItem > img {
  object-fit: contain;
  /*   background-color: white; */
  border-radius: 5px;
  height: 90% !important;
  width: 90% !important;
}
.gridItem:hover {
  background-color: rgb(105, 166, 190) !important;
}
.gridItem {
}
.wrapper {
  position: fixed;
  width: 30%;
  right: 0;
  min-width: 300px;
  background-color: rgb(255, 253, 240);
  height: 100%;
  transition: 0.7s;
  z-index: 1004;
}
.wrapperInactive {
  right: -30%;
  width: 30%;
}
.bg-wrapper {
  background-color: rgb(255, 253, 240);
}
.navbar-brand-box {
  background-color: white !important;
}

.bodyWrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  width: 100%;
}
.asideElement {
  transition: ease-in 0.1s;
  cursor: pointer;
}
.asideElement:hover {
  background-color: rgb(255, 255, 255);
}
.UnsavedWrapper {
  width: 50%;
  min-width: 300px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
}
.containerUnsavedPopup {
  transition: 0.5s !important;
  top: -30%;
}
.simplebar-content-wrapper {
  background-color: #428afe !important;
}
.containerDisabled {
  top: 5px !important;
}
.metismenu li a {
  color: white !important;
  transition: none !important;
}
.metismenu li .active {
  font-weight: bold;
  letter-spacing: 0.1rem;
}
.metismenu li a i {
  color: white !important;
}
.sub-menu li a {
  color: rgb(228, 228, 228) !important;
}
.new-booking-phone-li {
  transition: 0.2s;
  z-index: 1;
}
.new-booking-phone-li:hover {
  background-color: rgb(169, 195, 219) !important;
}
.gridSeason {
  box-shadow: 0 0px 4px green;
}
.gridDaily {
  box-shadow: 0 0px 4px blue;
}
.badge-third {
  background-color: #f85cff;
}

.backdropBlur {
  backdrop-filter: blur(2px);
  background: rgb(2, 0, 36);
  background: linear-gradient(
    0deg,
    rgba(2, 0, 36, 0.4962359943977591) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}
